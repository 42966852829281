<sl-dialog [title]="mergedData.title" [styleClass]="mergedData.titleStyleClass" data-testid="confirmation-dialog-root-element">
	<ng-container content>
		<div class="sl-flexbox-gap-16 sl-typography-body {{ mergedData.contentStyleClass }}" data-testid="confirmation-dialog-content-element" [innerHTML]="mergedData.content"></div>
	</ng-container>
	<ng-container actions>
		@if (mergedData.showCancelButton && mergedData.cancelButtonCaption?.length) {
			<sl-text-button
				data-testid="confirmation-dialog-cancel-button"
				[label]="mergedData.cancelButtonCaption"
				[mat-dialog-close]="false">
			</sl-text-button>
		}
		<sl-button
			class="ml-lg"
			data-testid="confirmation-dialog-confirm-button"
			[label]="mergedData.confirmButtonCaption"
			[mat-dialog-close]="true">
		</sl-button>
	</ng-container>
</sl-dialog>

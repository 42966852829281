import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { InputTextModule } from 'primeng/inputtext';

import { AlertDialogData } from '@@core/models/alert-dialog.model';
import { ButtonComponent } from '@@shared/design/components/button/button.component';
import { TextButtonComponent } from '@@shared/design/components/text-button/text-button.component';

import { DialogComponent } from '../dialog/dialog.component';

@Component({
	selector: 'sl-alert-dialog',
	templateUrl: './alert-dialog.component.html',
	styleUrl: './alert-dialog.component.scss',
	standalone: true,
	imports: [
		DialogComponent,
		InputTextModule,
		MatButtonModule,
		MatDialogModule,
		FormsModule,
		ButtonComponent,
		TextButtonComponent
	]
})
export class AlertDialogComponent implements OnInit {
	inputValue: string;
	error: string;
	isLoading: boolean;
	readonly config = inject(MAT_DIALOG_DATA) as AlertDialogData;
	readonly #destroyRef = inject(DestroyRef);

	ngOnInit(): void {
		this.config.onError?.pipe(
			takeUntilDestroyed(this.#destroyRef)
		).subscribe(error => {
			this.error = error;
			this.isLoading = false;
		});

		if (this.config.inputValue) {
			this.inputValue = this.config.inputValue;
		}
	}

	onInputChange(): void {
		this.error = null;
	}

	onConfirm(): void {
		this.isLoading = true;
		if (this.config?.showInput) {
			this.config.onSave?.emit(this.inputValue);
		} else {
			this.config.onSave?.emit();
		}
	}
}

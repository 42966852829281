<sl-dialog [title]="config.title" class="alert-modal">
	<ng-container content>
		<div class="sl-flexbox-column sl-flexbox-gap-16">
			<p [innerHTML]="config.message"></p>
			@if (config.showInput) {
				<div class="sl-flexbox-column sl-flexbox-gap-8">
					@if (config.inputLabel) {
						<label class="input-label uppercase sl-typography-label" for="input-value">{{ config.inputLabel }}</label>
					}
					<input id="input-value"
							pInputText
							type="text"
							[placeholder]="config?.inputPlaceholder || ''"
							(input)="onInputChange()"
							[(ngModel)]="inputValue"
							autocorrect="off"
							spellcheck="false"
							autocomplete="off"
							[attr.maxlength]="config?.inputLimitationLength || 4196">
					@if (error) {
						<span class="sl-typography-body-medium error">{{ error }}</span>
					}
				</div>
			}
		</div>
	</ng-container>
	<ng-container actions>
		@if (config.cancelButtonCaption?.length) {
			<sl-text-button
				[label]="config.cancelButtonCaption"
				[disabled]="isLoading"
				[mat-dialog-close]="true">
			</sl-text-button>
		}
		<sl-button
			[label]="config.confirmButtonCaption"
			[disabled]="isLoading || (config?.showInput && !inputValue)"
			[mat-dialog-close]="config.closeOnConfirm"
			(click)="onConfirm()">
		</sl-button>
	</ng-container>
</sl-dialog>
